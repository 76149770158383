import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Text from '../../../components/shared/text'
import {AboutContentWrap, AboutContentTop} from './about-content-area.stc'
import Image from "../../../components/image";

const AboutContentArea = (props) => {
    const aboutData = useStaticQuery(graphql `
        query AboutContentQuery {
            aboutJson(id: {eq: "about-page-content"}) {
                banner1 {
                    childImageSharp {
                        fluid(maxWidth: 1160, maxHeight: 785, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                banner2 {
                    childImageSharp {
                        fluid(maxWidth: 1260, maxHeight: 585, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                top_content {
                    content {
                        text1
                        text2
                        text3
                        text4
                        text5
                        text6
                        text7
                        text8
                        text9
                        text10
                        text11
                        text12
                        text13
                        text14
                    }
                }
                list_left {
                    heading
                    items
                }
            }
        }
    `)
    const {banner1, banner2, top_content} = aboutData.aboutJson
    const {textCSS} = props;
    const banner1Img = banner1.childImageSharp.fluid;
    const banner2Img = banner2.childImageSharp.fluid;
    return (
        <AboutContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={4} xl={4}>
                        <Image className={'rounded'} fluid={banner1Img} alt="Ronnie Moonlight" style={{'marginBottom': '20px'}}/>
                    </Col>
                    <Col lg={4} xl={4}>
                        {top_content && (
                            <AboutContentTop>
                                {top_content.content.text1 && <Text {...textCSS}>{top_content.content.text1}</Text>}
                            </AboutContentTop>
                        )}
                    </Col>
                    <Col lg={10} xl={8}>
                        {top_content && (
                            <AboutContentTop>
                                {top_content.content.text2 && <Text {...textCSS}>{top_content.content.text2}</Text>}
                                {top_content.content.text3 && <Text {...textCSS}>{top_content.content.text3}</Text>}
                                {top_content.content.text4 && <Text {...textCSS}>{top_content.content.text4}</Text>}
                                {top_content.content.text5 && <Text {...textCSS}>{top_content.content.text5}</Text>}
                                {top_content.content.text6 && <Text {...textCSS}>{top_content.content.text6}</Text>}
                                {top_content.content.text7 && <Text {...textCSS}>{top_content.content.text7}</Text>}
                                {top_content.content.text8 && <Text {...textCSS}>{top_content.content.text8}</Text>}
                                {top_content.content.text9 && <Text {...textCSS}>{top_content.content.text9}</Text>}
                                <Image className={'rounded'} fluid={banner2Img} alt="about banner" style={{'marginBottom': '20px'}}/>
                                {top_content.content.text10 && <Text {...textCSS}><br/>{top_content.content.text10}</Text>}
                                {top_content.content.text11 && <Text {...textCSS}>{top_content.content.text11}</Text>}
                                {top_content.content.text12 && <Text {...textCSS}>{top_content.content.text12}</Text>}
                                {top_content.content.text13 && <Text {...textCSS}>{top_content.content.text13}</Text>}
                                {top_content.content.text14 && <Text {...textCSS}>{top_content.content.text14}</Text>}
                            </AboutContentTop>
                        )}
                    </Col>
                </Row>
            </Container>
        </AboutContentWrap>
    )
}

AboutContentArea.defaultProps = {
    textCSS: {
        mb: '26px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    },
}

export default AboutContentArea


  